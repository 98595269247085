<template>
  <div id="searchResult">
    <v-container fluid class="pa-2">
      <v-row fluid>
        <p class="pa-4" v-if="searchResults.length == 0">
          Keine Einträge vorhanden!
        </p>

        <v-col
          :cols="amount"
          class="pa-5"
          v-for="object in searchResults"
          :key="object.id"
          @click="
            cardClick(
                object
            )
          "
        >
          <detailCard
            v-if="object.vin"
            :plate="object.plate"
            :vin="object.vin"
            :imageFile="
              object.images.length > 0
                ? getDisplayImage(object)
                : require('../assets/images/placeholders/vehiclePlaceholder.jpg')
            "
            :user="object.vehiclesToUser"
            :owner="object.currentOwner"
            :last_updated="object.last_updated"
            :pricingNeeded="!object.bFinishedPricing"
            :assessmentNeeded="!object.bFinishedReport"
          />
          <modelCard
            v-else
            :name="object.name"
            :imageFile="object.imageFile"
            :count="object.count"
            :needAssessment="object.needAssessmentCount > 0"
            :needPricing="object.needPricingCount > 0"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import detailCard from "../components/DetailCard";
import modelCard from "../components/ModelCard";

export default {
  name: "searchResult",

  props: ["searchResults"],

  data() {
    return {
      selectedModel: "",
    };
  },

  watch: {
  searchResults: function() {
    this.$forceUpdate()
  },
  },

  components: {
    detailCard,
    modelCard
  },

  computed: {
    amount() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 6;
        case "md":
          return 4;
        case "lg":
          return 3;
        case "xl":
          return 2;
        default:
          return 3;
      }
    },
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
      localStorage.setItem("selectedModel", "");
    },

    cardClick(object) {
      if(object.vin){
        localStorage.setItem("selectedVehicle", object.vin);
        localStorage.setItem("selectedVehicleType", object.vehiclesToModel.vehicleManufacturerModelsToVehicleType);
      }
      else{
        localStorage.setItem("selectedModel", object.name);
        this.$router.push("/stock/model/" + btoa(object.name));
      }
    },
    getDisplayImage(val){
      for(var i = 0; i < val.images.length; i++){
        if(val.images[i].slot == "left") return val.images[i].imageFile
      }

      return val.images[0].imageFile
    },
  },
 
};
</script>
