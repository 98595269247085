<template>
  <div id="stockPage">
    <headerComponent
      :titleProp="pageTitle"
      :bHasSearchBar="bHasSearchBar"
      v-model="searchResults"
    />

    <v-container fluid>
      <v-row>
        <v-spacer> </v-spacer>
        <v-col cols="auto">
          <router-link
            v-if="getPerm(4) && this.$route.path != '/stock/search'"
            to="/stock/new/overview"
            id="plusIcon"
          >
            <div class="tooltip">
              <v-icon class="icon-off" id="plusIcon">mdi-plus-box</v-icon>
              <span class="tooltiptext">Neues Fahrzeug</span>
            </div>
          </router-link>

          <div class="tooltip" v-else>
            <v-icon disabled class="icon-off" id="plusIcon" style="opacity: 0.3"
              >mdi-square-off-outline</v-icon
            >
            <span class="tooltiptext">Neues Fahrzeug</span>
          </div>
        </v-col>
        <v-col cols="auto" v-if="!bShowInHouse && this.$route.path == '/stock'">
          <div class="tooltip">
            <v-icon class="icon-off" @click="switchModelView"
              >mdi-garage</v-icon
            >
            <span class="tooltiptext">Intern</span>
          </div>
        </v-col>
        <v-col
          cols="auto"
          v-else-if="bShowInHouse && this.$route.path == '/stock'"
        >
          <div class="tooltip">
            <v-icon class="icon-on" @click="switchModelView">mdi-garage</v-icon>
            <span class="tooltiptext">Intern</span>
          </div>
        </v-col>

        <v-col cols="auto" v-if="bShowFinished">
          <div class="tooltip">
            <v-icon
              v-if="this.$route.path != '/stock/search'"
              class="icon-on"
              @click="switchFilteredView('finished')"
              >mdi-check
            </v-icon>
            <span class="tooltiptext">Abgeschlossen</span>
          </div>
        </v-col>
        <v-col cols="auto" v-else>
          <div class="tooltip">
            <v-icon
              v-if="this.$route.path != '/stock/search'"
              class="icon-off"
              @click="switchFilteredView('finished')"
              >mdi-check
            </v-icon>
            <span class="tooltiptext">Abgeschlossen</span>
          </div>
        </v-col>

        <v-col cols="auto" v-if="bShowAssessmentNeeded">
          <div class="tooltip">
            <v-icon
              v-if="this.$route.path != '/stock/search'"
              class="icon-on"
              @click="switchFilteredView('report')"
              >mdi-tools</v-icon
            >
            <span class="tooltiptext">Gutachten unfertig</span>
          </div>
        </v-col>
        <v-col cols="auto" v-else>
          <div class="tooltip">
            <v-icon
              v-if="this.$route.path != '/stock/search'"
              class="icon-off"
              @click="switchFilteredView('report')"
              >mdi-tools</v-icon
            >
            <span class="tooltiptext">Gutachten unfertig</span>
          </div>
        </v-col>

        <v-col cols="auto" v-if="bShowPricingNeeded">
          <div class="tooltip">
            <v-icon
              v-if="this.$route.path != '/stock/search'"
              class="icon-on"
              @click="switchFilteredView('price')"
              >mdi-currency-eur</v-icon
            >
            <span class="tooltiptext">Bepreisung unfertig</span>
          </div>
        </v-col>
        <v-col cols="auto" v-else>
          <div class="tooltip">
            <v-icon
              v-if="this.$route.path != '/stock/search'"
              class="icon-off"
              @click="switchFilteredView('price')"
              >mdi-currency-eur
            </v-icon>
            <span class="tooltiptext">Bepreisung unfertig</span>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <router-view
      v-model="currModelName"
      :modelName="currModelName"
      :bShowAllModels="bShowAllModels"
      :bShowPricingNeeded="bShowPricingNeeded"
      :bShowFinished="bShowFinished"
      :bShowAssessmentNeeded="bShowAssessmentNeeded"
      :bShowInHouse="bShowInHouse"
      :bHasChanged="bHasChanged"
      :searchResults="searchResults"
    />
  </div>
</template>

<script>
import headerComponent from "../components/Header.vue";

export default {
  name: "stock",

  data() {
    return {
      pageTitle: "Modellübersicht",
      currModelName: undefined,
      bHasSearchBar: true,

      bShowAllModels: false,
      bShowPricingNeeded: false,
      bShowAssessmentNeeded: false,
      bHasChanged: false,
      bShowFinished: false,
      bShowInHouse: true,

      searchResults: {},

      eur_on: true,
    };
  },

  components: {
    headerComponent,
  },

  computed: {
    amount() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 6;
        case "md":
          return 4;
        case "lg":
          return 3;
        case "xl":
          return 2;
        default:
          return 3;
      }
    },
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    switchModelView() {
      this.bShowInHouse = !this.bShowInHouse;
      this.bHasChanged = !this.bHasChanged;

      this.$forceUpdate();
    },
    switchFilteredView(value) {
      if (value == "report") {
        this.bShowPricingNeeded = false;
        this.bShowFinished = false;
        this.bShowAssessmentNeeded = !this.bShowAssessmentNeeded;
      } else if (value == "price") {
        this.bShowAssessmentNeeded = false;
        this.bShowFinished = false;
        this.bShowPricingNeeded = !this.bShowPricingNeeded;
      } else if (value == "finished") {
        this.bShowAssessmentNeeded = false;
        this.bShowPricingNeeded = false;
        this.bShowFinished = !this.bShowFinished;
      } else if (value == "house") {
        this.bShowInHouse = !this.bShowInHouse;
      }

      this.bHasChanged = !this.bHasChanged;

      this.$forceUpdate();
    },
    getPerm(val) {
      var perm = localStorage.getItem("perm");
      if (perm <= parseInt(val)) return true;

      return false;
    },
  },
  mounted() {
    window.scrollTo(0, 0);

    if (this.$route.params.model) {
      this.currModelName = atob(this.$route.params.model);
      localStorage.setItem("selectedModel", this.currModelName);
      this.bHasSearchBar = false;
    } else if (this.$route.path == "/stock") {
      this.currModelName = undefined;
      this.bHasSearchBar = true;
    } else if (this.$route.path == "/stock/search") {
      this.currModelName = undefined;
      this.bHasSearchBar = true;
    }
  },
  updated() {
    if (this.$route.params.model) {
      this.currModelName = atob(this.$route.params.model);
      localStorage.setItem("selectedModel", this.currModelName);
      this.bHasSearchBar = false;
    } else if (this.$route.path == "/stock") {
      this.currModelName = undefined;
      this.bHasSearchBar = true;
    }

    if (this.$route.path == "/stock/search") {
      this.pageTitle = "Suche";
    } else if (this.currModelName) {
      this.pageTitle = "Modell " + this.currModelName;
    } else {
      this.pageTitle = "Modellübersicht";
    }
  },
};
</script>